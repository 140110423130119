import React from "react";

export default function PrivacyPolicy() {
    return(
        <div className="privacyPolicy">
            <div className="markdown">
                <h1>Privacy Policy</h1>
                <p>Last updated: August 24, 2022</p>
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <h1>Interpretation and Definitions</h1>
                <h2>Interpretation</h2>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h2>Definitions</h2>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                    <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                    <li><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to InterSpot.</li>
                    <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
                    <li><strong>Country</strong> refers to: British Columbia,  Canada</li>
                    <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                    <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                    <li><strong>Service</strong> refers to the Website.</li>
                    <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                    <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                    <li><strong>Website</strong> refers to InterSpot, accessible from <a href="interspot.net" rel="external nofollow noopener" target="_blank">interspot.net</a></li>
                    <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                </ul>
                <h1>Collecting and Using Your Personal Data</h1>
                <h2>Types of Data Collected</h2>
                <h3>Spotify Data</h3>
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                <ul>
                <li>The name You entered on Spotify when signing-up</li>
                <li>Your Spotify profile picture</li>
                <li>Playlists You have created on Spotify</li>
                <li>Playlists You follow on Spotify</li>
                <li>Your Spotify ID</li>
                </ul>
                <h3>Usage Data</h3>
                <p>Usage Data is collected automatically when using the Service.</p>
                <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                <h3>Cookies</h3>
                <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">TermsFeed website</a> article.</p>
                <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                <ul>
                    <p><strong>Necessary / Essential Cookies</strong></p>
                    <p>Type: Session Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                </ul>

                <h2>Use of Your Personal Information</h2>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                    <li>To create a good user experience</li>
                    <li>To provide our services, including generating a playlist for You</li>
                </ul>
                <p>InterSpot does not sell, or make money from Your information in any way. InterSpot is not monetized. It is a free, open-source software available to the public.</p>

                <h2>Retention of Your Personal Data</h2>
                <p>InterSpot does not retain any of Your personal information.</p>
                <p>InterSpot does not sell, or make money from Your information in any way. InterSpot is not monetized.</p>
                <p>All the information received are handled and remain in the client side (Your browser). No information is sent to an InterSpot server. All the requests and responses to and from the Spotify API are respectively sent and received by the client side (Your browser), and not by a server.</p>
                <p>All the information received remain in the Your browser for the duration of the session (the time the InterSpot tab is open). InterSpot will no longer access Your data, or have permission to access Your data after the session is over (when Your close the InterSpot tab).</p>

                <h2>Security of Your Personal Data</h2>
                <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                <p>InterSpot is an open-source software, meaning that its source-code is available to the public. You can view the source code at <a href="https://github.com/parsasaeedi/interspot">www.github.com/parsasaeedi/interspot</a>. The source-code being available to the public means that if there are any security vulnerabilities in the code, it will be available to the public. While We do our best to keep InterSpot safe, it is not a 100% guarantee.</p>
                <p>InterSpot uses the HTTPS protocol.</p>
                <p>InterSpot is hosted on GitHub Pages. To view their privacy policy, visit <a href="https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement">GitHub's Privacy Statement</a></p>
                <p>InterSpot does not handle user sign-in, and therefore, does not have access to Your log-in information. Instead, it uses an authorization method called OAuth 2.0. For more on OAuth, visit <a href="https://developer.spotify.com/documentation/general/guides/authorization/">Spotify Authorization</a>. Implementing OAuth 2.0, InterSpot redirects You to an official Spotify website for authorization.</p>
                <p>More on Authorization in the next section.</p>

                <h2>User Authorization</h2>
                <p>In the official Spotify authorization website, You give InterSpot permission to:</p>
                <ul>
                    <li>View the type of Spotify subscription You have</li>
                    <li>View Your account country and Your settings for explicit content filtering</li>
                    <li>View Your name and username, Your profile picture, how many followers You have on Spotify</li>
                    <li>View Your public playlists</li>
                    <li>View what You've saved in Your Library</li>
                    <li>View the playlists You've made and playlists You follow</li>
                    <li>Create, edit, and follow playlists</li>
                </ul>
                <p>That is the scope of the permission. InterSpot cannot view anything else in Your account, or modify anything other than Your playlists in Your account.</p>
                <p>After signing in, the official Spotify authorization website redirects You back to InterSpot.net with an authorization code in the URL InterSpot uses that authorization code to request for an access-token from Spotify.</p>
                <p>The access token gives InterSpot permission to make requests to the Spotify API to access the information mentioned above.</p>
                <p>InterSpot keeps the access-token for the duration of the session (while the InterSpot tab is open) and loses access to it when You end the session (You close the InterSpot tab)</p>
                <p>The session does not end by refreshing the InterSpot page, therefore, InterSpot does not lose permission to access the data mentioned above when You refresh the InterSpot page.</p>
                <p>The session only ends when You close the InterSpot tab/page, close the browser window in which InterSpot is open, or close the browser on which InterSpot is open. When You open a new InterSpot tab/page, You need to sign-in again.</p>

                <p>InterSpot uses the access-token to:</p>
                <ul>
                    <li>Get Your name, profile picture, Spotify ID, and a list of Your playlists (name, cover, and ID of the playlists).</li>
                    <li>Create a playlist in Your account, add items to it, and make the second user follow that playlist.</li>
                </ul>

                <p>By signing in to Your Spotify account in the official Spotify authorization website, You give InterSpot an access-token that gives InterSpot access to the data before mentioned. Sixty minutes after signing in, the access-token will be expired.</p>
                <p>Every access-token is accompanied by a refresh-token. InterSpot uses refresh-tokens to ask for a new access-token every 58 minutes after signing in, until the session is closed (when the user closes the InterSpot tab).</p>
                <p>For more on Spotify OAuth, visit <a href="https://developer.spotify.com/documentation/general/guides/authorization/">Spotify Authorization</a></p>
                
                <p>InterSpot has permission to view, but does not use:</p>
                <ul>
                    <li>The type of Spotify subscription You have</li>
                    <li>Your account country and Your settings for explicit content filtering</li>
                    <li>The email address You signed-up for Spotify with</li>
                    <li>How many followers You have on Spotify</li>
                    <li>What You've saved in Your Library</li>
                </ul>
                <p>InterSpot has permission to modify all Your playlists, but the only playlist that it modifies is the intersection playlist that it creates for You.</p>

                
                <h1>Children's Privacy</h1>
                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.</p>
                <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                <h1>Links to Other Websites</h1>
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <h1>Changes to this Privacy Policy</h1>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h1>Contact Us</h1>
                <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                <ul>
                <li>By email: contact@parsa.dev</li>
                </ul>
            </div>
        </div>
    )
}